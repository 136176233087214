@use "../utils/" as *;

.chatbot {
  position: relative;
  top: 0;
  right: 0;
  width: 80%;
  &.-pop {
    width: 100%;
    top: auto;
    right: auto;
    margin-bottom: 0;
    background: none;
    border: em(2) solid rgba(137, 219, 236, 1);
    border-radius: em(15);
    .close-icon {
      display: none;
    }
    .chat-scroll {
      .chat-info {
        .user-msg {
          width: 80%;
        }
      }
    }
  }
  margin-bottom: em(40);
  margin-top: 0;
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    margin: em(5);
  }
  .chat-scroll {
    overflow-y: scroll;
    scrollbar-width: thin;
    height: 70vh;
    .chat-info {
      display: flex;
      flex-direction: column;
      .user-msg {
        padding: em(5) em(10);
        margin: em(10);
        background-color: var(--gray-30);
        color: #000000;
        border-radius: 10px;
        width: 60%;
        @include respond(tab-port) {
          width: 80%;
        }
        align-items: flex-start;
        justify-content: flex-start;
      }

      .bot-msg {
        padding: em(5) em(10);
        margin: em(10);
        background-color: rgb(29, 32, 36);
        color: #000000;
        border-radius: 10px;
        width: 80%;
        align-self: flex-end;
        justify-content: flex-end;
        @include respond(tab-port) {
          align-self: flex-start;
          justify-content: flex-start;
        }
      }
    }
  }
}

.main-chat {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.chat-intro {
  color: var(--gray-40);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 4px;
}
.prompt {
  display: flex;
  justify-content: space-between;
  height: 10vh;
  align-items: center;
  margin-bottom: 0;
  width: 100%;
  &.-pop {
    .chat-input {
      margin: em(20);
    }
    .chat-icon {
      flex: 2;
    }
    .prompt-bg {
      height: 50%;
    }
  }
}
.prompt-bg {
  border: 1px solid rgb(194, 194, 194);
  border-radius: 20px !important;
  padding: em(10);
  margin: em(5);
}

.chat-input {
  border: 1px solid #9e9e9e !important;
  flex: 9;
  padding: em(10);
  margin: em(5);
  border-radius: em(15);
}
.chat-icon {
  flex: 1;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 980px) {
  .chat-scroll {
    height: 90vh;
  }
}

.blinking-cursor {
  -webkit-animation: 0.4s blink step-end infinite;
  -moz-animation: 0.4s blink step-end infinite;
  animation: 0.4s blink step-end infinite;
  color: #89dbecc7;
}

.loading > .spinner-grow {
  background-color: #000000;
  height: 0.5rem !important;
  width: 0.5rem !important;
}
.loading > .spinner-grow:nth-child(2) {
  animation-delay: 50ms;
}
.loading > .spinner-grow:nth-child(3) {
  animation-delay: 100ms;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgba(137, 219, 236, 1);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.container-ai {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #101010;
  // margin-bottom: -20px;
  @include respond(tab-land) {
    // margin-bottom: -5px;
  }
  @include respond(phone) {
    // margin-bottom: 40px;
  }

  .askai {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(137, 219, 236, 1);
    text-align: center;
    border: 2px solid rgba(137, 219, 236, 1);
    background: rgb(4, 26, 37);
    background: linear-gradient(
      90deg,
      rgba(4, 26, 37, 1) 0%,
      rgba(11, 50, 57, 1) 35%,
      rgba(8, 13, 14, 1) 100%
    );
    width: 80%;

    border-radius: 15px;
    font-size: 18px;
    padding: 4px;
    margin: 10px;
    @include respond(tab-land) {
      width: 90%;
      font-size: 10px;
      padding: 0 1rem;
    }
    @include respond(phone) {
      padding: 0 0.5rem;
    }
  }
}

.form-overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background: rgb(4, 26, 37);
  background: linear-gradient(
    90deg,
    rgba(4, 26, 37, 1) 0%,
    rgba(11, 50, 57, 1) 35%,
    rgba(8, 13, 14, 1) 100%
  );
  z-index: 9999;

  border-radius: em(15);
  margin: em(60) em(20);
  height: 80%;
  width: 30%;
  .form-container {
    width: 100%;
  }
}
.button-overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: rgb(137, 219, 236);
  z-index: 9999;   -webkit-box-shadow:0px 0px 2px 2px rgba(137,219,236,1);
  -moz-box-shadow: 0px 0px 2px 2px rgba(137,219,236,1);
  box-shadow: 0px 0px 2px 2px rgba(137,219,236,1);


  border-radius: em(50);
  margin: em(20);
  .icon {
    width: em(40);
    height: em(40);
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
}
