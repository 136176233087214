@use "../utils/" as *;

.about {
  background-color: #101010;

  .hero {
    position: relative;
    height: 500px;
    background-size: cover;
    background-position: bottom;
    background-image: url("../../assets/futur.jpg");

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.8;
      filter: blur(20px);
    }

    .content {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;

      .text {
        font-size: 3rem;
        color: rgba(255, 255, 255, 0.9);
        padding: 0 2rem;
        max-width: 800px;
        margin-bottom: 1em;

        @include respond(tab-land) {
          width: 80%;
          font-size: 2rem;
          padding: 0 1rem;
        }
        @include respond(phone) {
          padding: 0 0.5rem;
        }
      }
      .subtext {
        font-size: 1.1rem;
        color: rgba(255, 255, 255, 0.9);
        padding: 0 2rem;
        max-width: 800px;
        @include respond(tab-land) {
          width: 80%;

          font-size: 1rem;
          padding: 0 0.5rem;
        }

        @include respond(phone) {
          font-size: 0.8rem;
          padding: 0 0.25rem;
        }
      }
    }
  }
  .vision {
    position: relative;
    background: rgb(4, 26, 37);
    background: linear-gradient(
      90deg,
      rgba(4, 26, 37, 1) 0%,
      rgba(11, 50, 57, 1) 60%,
      rgba(8, 13, 14, 1) 100%
    );
    margin-bottom: em(40);

    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .text {
        font-size: 2rem;
        justify-content: center;
        display: flex;
        align-items: center;
        @include respond(tab-land) {
          justify-content: start;
          width: 90%;
          margin-left: em(50);
        }
        @include respond(tab-port) {
          margin-left: em(20);
          width: 90%;
        }
      }
      .subtext {
        max-width: 800px;
        display: flex;
        vertical-align: baseline;
        align-items: center;
        margin-bottom: 40px;
        margin-left: em(250);

        @include respond(tab-land) {
          margin-left: em(90);
          width: 80%;
        }
        @include respond(tab-port) {
          justify-content: space-evenly;
          margin-left: em(30);
          flex-direction: column;
          align-items: left;
        }
      }
    }
  }
  .testimonials {
    display: flex;
    flex-direction: column;
    background-color: none;
    margin-bottom: em(40);
    .roadmap {
      justify-content: center;
      // max-width: 700px;
      display: flex;
      align-items: center;
      @include respond(tab-land) {
        justify-content: start;
        width: 90%;
        margin-left: em(50);
      }
      @include respond(tab-port) {
        margin-left: em(20);
        width: 90%;
      }
    }

    .test {
      height: 300px;
      margin-bottom: 40px;
      padding: em(10) 0;
      width: em(200);
      display: flex;
      flex-direction: column;
    }
  }
  .timeline {
    display: flex;
    flex-direction: column;
    background: url("../../assets//roadmap.svg");
    background-repeat: no-repeat;
    background-position: left -300px;
    background-attachment: fixed;
    .roadmap {
      justify-content: center;
      display: flex;
      align-items: center;
      @include respond(tab-land) {
        justify-content: start;
        width: 90%;
        margin-left: em(50);
      }
      @include respond(tab-port) {
        margin-left: em(20);
        width: 90%;
      }
    }
    .checkpoints {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: em(10);
      @include respond(tab-port) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .point {
        display: flex;
        flex-direction: column;
        width: 40%;
        justify-content: flex-start;
        @include respond(tab-port) {
          justify-content: center;
          align-items: center;
          width: 90%;
          margin: 0 em(10);
        }

        .card {
          background: #0f2027; /* fallback for old browsers */
          background: -webkit-linear-gradient(
            to right,
            #2c5364,
            #203a43,
            #0f2027
          ); /* Chrome 10-25, Safari 5.1-6 */
          background: linear-gradient(
            to right,
            #2c5364,
            #203a43,
            #0f2027
          ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

          padding: em(10);
          border-radius: em(15);
          font-weight: 300;
          font-size: small;
          width: 50%;
          &:nth-child(1),
          &:nth-child(5),
          &:nth-child(6) {
            background-color: pink;
            align-self: center;
            @include respond(tab-port) {
              align-self: flex-start;
            }
          }
          &:nth-child(2) {
            background-color: pink;
            align-self: flex-end;
            @include respond(tab-port) {
              align-self: flex-start;
            }
          }

          .quarter {
            display: flex;
            font-weight: bold;
            vertical-align: baseline;
            align-items: center;
          }
        }
        .connect {
          margin: 0;
          width: 50%;
          height: em(100);
          display: flex;
          align-self: flex-end;
          @include respond(tab-port) {
            align-self: flex-end;
          }
          &:nth-child(odd) {
            align-self: flex-start;
            @include respond(tab-port) {
              align-self: flex-end;
            }
          }

          &:last-child {
            display: none;
            @include respond(tab-port) {
              display: block;
            }
          }
        }
      }
    }
    .event {
      max-width: 800px;
      display: flex;
      vertical-align: baseline;
      align-items: center;
      margin-bottom: 40px;
      margin-left: em(250);

      @include respond(tab-land) {
        margin-left: em(90);
        width: 80%;
      }
      @include respond(tab-port) {
        justify-content: space-evenly;
        margin-left: em(30);
        flex-direction: column;
        align-items: left;
      }

      .bullet {
        width: 10px;
        height: 10px;
        margin-right: 10px;
        @include respond(tab-port) {
          display: none;
        }
        &.blue {
          background-color: #66d2e7c7;
        }
        &.green {
          background-color: #2fc280;
        }
        &.purple {
          background-color: rgb(194, 87, 194);
        }
        &.teal {
          background-color: teal;
        }
        &.orange {
          background-color: #e5bd73;
        }
        &.pink {
          background-color: #e5739b;
        }
        &.yellow {
          background-color: #cfcf63;
        }
        &.magenta {
          background-color: #641064;
        }
      }

      .date {
        font-weight: bold;
        margin-left: 5px;
        @include respond(tab-port) {
          display: inline-flex;
          margin-left: 10px;
          width: 100%;
        }
      }

      .description {
        @include respond(tab-port) {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
  .team {
    background: rgb(4, 26, 37);
    background: linear-gradient(
      90deg,
      rgba(4, 26, 37, 1) 0%,
      rgba(11, 50, 57, 1) 35%,
      rgba(8, 13, 14, 1) 100%
    );
  }

  .partnerships {
    display: flex;
    flex-direction: column;

    .text {
      font-size: 2rem;
      justify-content: center;
      display: flex;
      align-items: center;

      @include respond(tab-land) {
        justify-content: start;
        width: 90%;
        margin-left: em(50);
      }
      @include respond(tab-port) {
        margin-left: em(20);
        width: 90%;
      }
    }

    .subtext,
    .subhead {
      display: flex;
      align-items: center;
      margin-left: em(250);
      max-width: 700px;

      color: rgba(255, 255, 255, 0.9);
      padding: 0 2rem;
      @include respond(tab-land) {
        width: 80%;
        margin-left: em(40);

        font-size: 1rem;
        padding: 0 0.5rem;
      }

      @include respond(phone) {
        font-size: 0.8rem;
        padding: 0 0.25rem;
        margin-left: em(20);
      }
    }
    .subhead {
      width: 80%;
      text-decoration: underline;
      text-decoration-color: rgba(137, 219, 236, 0.779);
      text-decoration-thickness: em(3);
    }
  }
  .card-grid {
    max-width: 800px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(em(300), 1fr));
    gap: 1rem;
    margin-left: em(250);
    margin-bottom: em(40);
    @include respond(tab-land) {
      margin-left: em(50);
    }
    @include respond(tab-port) {
      margin-left: em(30);
    }
    .card {
      width: em(300);
      display: flex;
      position: relative;
      flex-direction: column;
      align-content: flex-start;

      .imgbox {
        width: 100%;
        overflow: hidden;
        min-height: em(230);
        .img {
          height: em(220);
          max-width: 100%;
          max-height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: 50% 50%;
          border-radius: em(8) em(8) 0 0;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        padding: 20px;
        &.-team {
          align-items: left;
        }
        @include respond(tab-port) {
          align-items: left;
        }
        .company {
          font-size: 1rem;
          color: rgba(255, 255, 255, 0.9);

          &.-team {
            display: flex;
            width: 100%;
            align-items: flex-end;
            margin-bottom: em(10);
            flex-direction: row;

            &:hover {
              color: rgba(102, 210, 231, 0.779);
            }
          }
        }
        .avatar {
          width: em(80);
          height: em(80);
          margin-right: em(10);
          img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
          }
        }
        .description {
          font-size: 0.8rem;
          text-align: left;
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
  .contactlayer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: em(5);
    margin-bottom: em(20);
    .contact {
      border-radius: em(5);
      padding: em(10);
      width: 90%;
      align-items: center;
      display: flex;
      vertical-align: baseline;
      @include respond(tab-land) {
        flex-direction: column;
        width: 80%;
      }
      .cta {
        flex: 2;
        font-size: 0.8rem;
        padding: 0;
        margin: 0;
        @include respond(tab-land) {
          flex: 1;
        }
      }
      .form {
        flex: 3;
        padding: 0;
        margin: 0;
        @include respond(tab-land) {
          width: 100%;
        }
      }
    }
  }
}

.marquee {
  height: 300px;
  margin-top: 0;
  &.-partner {
    height: 100px;
  }
  background-color: #101010;
  .card {
    margin: 0 em(40);
    padding: em(20);
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    max-width: em(400);
    background: #0f2027; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #2c5364,
      #203a43,
      #0f2027
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #2c5364,
      #203a43,
      #0f2027
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: em(15);
    .carditem {
      margin-bottom: em(20);
      font-size: small;

      &.-info {
        font-weight: 200;
      }
    }
  }
  .partnercard {
    margin: 0 em(20);
    padding: em(20);
    display: flex;
    max-width: em(400);
    background: #0f2027; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #2c5364,
      #203a43,
      #0f2027
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #2c5364,
      #203a43,
      #0f2027
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: em(5);
    .carditem {
      font-size: small;
      padding: 0;

      &.-info {
        &:hover {
          color: rgba(102, 210, 231, 0.779);
        }
      }
    }
    .cardlogo {
      margin: 0 em(5);
      height: em(20);
      padding: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.container {
  margin-top: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.box {
  margin-bottom: 20px;
  background: rgb(4, 26, 37);
  background: linear-gradient(
    90deg,
    rgba(4, 26, 37, 1) 0%,
    rgba(11, 50, 57, 1) 35%,
    rgba(8, 13, 14, 1) 100%
  );
  color: white;
  border-radius: 8px;
  width: 60%;
  @include respond(tab-land) {
    width: 80%;
  }
  @include respond(tab-port) {
    width: 95%;
  }
  padding: 6px;

  .inner-box {
    padding: 10px;

    .title {
      font-size: 18px;
      margin-bottom: 8px;
    }

    .subtitle {
      margin-top: 8px;
      margin-bottom: 16px;
      font-weight: 200;
      font-size: 14px;
    }

    .button-group {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      .contact-button {
        display: flex;

        color: rgba(102, 210, 231, 0.779);
        border: none;
        cursor: pointer;

        &:hover {
          color: rgba(191, 234, 244, 0.779);
        }

        .icon {
          margin-right: 8px;
        }
      }
    }
  }
}
