@use "../utils/" as *;

.blog {
  max-width: 1300px;
  margin: auto;
  background-color: #101010;
  padding: em(30) 0;
  .breadcrumb {
    //todo
    color: white;
  }
  h1 {
    margin: 0;
    font-size: em(42);
    font-weight: 600;
    text-align: center;
    @include respond(phone) {
      font-size: em(32);
    }
  }
  .info_div {
    display: flex;
    justify-content: center;
    column-gap: em(200);
    padding: em(15) 0;
    font-size: em(20);
    font-weight: 200;
    @include respond(phone) {
      column-gap: em(30);
      font-size: em(18);
    }
  }
  .img_div {
    width: 70%;
    margin: auto;
    height: em(500);
    img {
      width: 100%;
      height: 100%;
    }
    @include respond(phone) {
      height: em(300);
    }
  }
  .content-div {
    display: flex;
    flex-direction: column;
    // align-items: center;
    h4 {
      font-size: em(24);
      font-weight: 600;
      line-height: em(30);
      @include respond(phone) {
        font-size: em(20);
        line-height: em(24);
        font-weight: 600;
      }
    }
    p {
      display: inline-block !important;
      text-align: start !important;
      opacity: 0.7;
      font-size: em(20);
      a {
        text-decoration: underline;
        color: #1976d2;
      }
      @include respond(phone) {
        font-size: em(18);
        line-height: em(28);
      }
    }
    ol {
      list-style-type: decimal !important;
      li {
        list-style-type: decimal !important;
        opacity: 0.7;
        font-size: em(20);
        @include respond(phone) {
          font-size: em(18);
          line-height: em(28);
        }
      }
    }
    ul {
      list-style-type: disc !important;
      li {
        margin-left: em(30);
        list-style-type: disc !important;
        opacity: 0.7;
        font-size: em(20);
        @include respond(phone) {
          font-size: em(18);
          line-height: em(28);
        }
      }
    }
    img {
      width: 80% !important;
      margin: auto;
      @include respond(phone) {
        height: em(300);
      }
    }
  }
  @include respond(phone) {
    padding: em(15);
  }
}
