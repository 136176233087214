@use "../utils" as *;

.bannerReAnalytics {
  border-radius: 10px;
  width: 100%;
 
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  h5 {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: var(--bg);
    margin-bottom: 0;
    font-weight: 700;
    margin-top: 0;
    
    .gradient-text {
      background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
      background-clip: text;
      -webkit-background-clip: text; /* for Safari/WebKit browsers */
      color: transparent;
      // text-fill-color: transparent;
    }
  }
}
