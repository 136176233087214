@use "../utils/" as *;

.button {
  color: var(--white);
  border-radius: 0 em(5) em(5) 0 !important;
  border: em(1) solid var(--main-1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: em(40);
  padding: 0 em(20);
  font-size: rem(14);
  transition: all 0.3s ease-in-out;
  background-color: var(--main);
  &:hover {
    background-color: var(--secondary);
    outline: none;
    text-decoration: none;
  }

  &:focus {
    outline-style: solid;
    outline-color: transparent;
    box-shadow: 0 0 0 em(1) var(--main-1);
  }
  &.-newsletter {
    border-radius: 0 em(10) em(10) 0;
    @include respond(phone) {
      // border-radius: em(25);
    }
  }
}
