@use "../utils/" as *;

.sidebar {
  display: none;

  @include respond(tab-port) {
    display: block;
    position: fixed;
    background: #1c92d2;
    background: -webkit-linear-gradient(to right, #f2fcfe, #1c92d2);

    background: linear-gradient(to right, #f2fcfe, #1c92d2);
    top: 0;
    right: 0;
    z-index: 1500;
    width: 100%;
    max-width: 100%;
    height: 100%;
    bottom: 0;
    transition: transform 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
    padding: 2rem 1rem;
    overflow: hidden;
    visibility: visible;
    font-weight: 500;
    color: var(--bg);
    align-items: center;
  }

  &--open {
    transform: translateX(0);
  }

  &--closed {
    transform: translateX(100%);
  }

  &__backdrop {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    background: rgba(28, 146, 210, 0.8);
    transition: transform 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
  }
  .container {
    display: flex;
    min-height: 100%;
    width: 100%;
    justify-content: center;
    margin-top: em(40);
    align-items: center;

    &__list {
      transition: transform 0.3s ease-out;
      -webkit-transition: 0.3s ease-out;
      @include respond(tab-land) {
        width: 100%;
        height: 100%;
      }
      &.-avatar {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(em(100), 1fr));
        &__item {
          border-bottom: none;
          color: var(--text-2);
          width: 50%;

          font-size: 1.2rem;
          &:hover {
            border-radius: em(10);
            background-color: var(--gray-20);
          }
        }
      }
      &__item {
        text-align: center;
        margin: em(40) 0;
        font-weight: 600;
        font-size: 1rem;
        &:hover {
          border-radius: em(10);
          color: var(--main);
          background-color: var(--light);
        }
      }
    }
    &__link {
      display: block;
      transition: transform 0.5s ease-out;
      text-decoration: none;
      padding: 0.5em 0.5em;
      &:hover {
        color: var(--main);
        background-color: var(--light);
      }
    }
  }
}

.sidebar-relisting {
  position: fixed;
  z-index: 4000;
  transition: 0.3s ease all;
  left: 0;

  .menu {
    color: var(--main);
    background-color: var(--gray-10);
    border-radius: em(10);

    &__list {
      &__item {
        padding: em(10) em(20);
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        margin: em(20) em(10);
        font-size: rem(14);
        &:hover {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
          transform: scale(1.1);
          background-color: var(--light-3);
          border-radius: em(10);
          cursor: auto;
        }
        &:last-child,
        &:nth-child(6) {
          font-weight: bold;
          background-color: var(--main-1);
          color: var(--bg);
          border-radius: em(10);
          text-align: center;
          &:hover {
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
          }
        }
      }
    }
  }
}
