.no_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: em(300);
}

.ag-theme-alpine {
  --ag-background-color: #181d1f !important;
}

.ag-header-row {
  background-color: #222628 !important;
  color: white !important;
}
.ag-row-even {
  // background-color: red !important;
  color: white !important;
  &:hover {
    background-color: rgba(33, 150, 243, 0.1) !important;
    color: white;
  }
}

.ag-row-odd {
  background-color: #222628 !important;
  color: white !important;
}
