@use "../utils/" as *;

.listing {
  background-color: rgb(34, 38, 40);
  width: em(500);
  border-radius: em(10);
  padding: em(5);
  @include respond(phone) {
    width: em(300);
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-box-align: center;
    margin-bottom: em(2);
    white-space: nowrap;
    overflow: hidden;
    .label {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      overflow: hidden;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: rem(16);
        font-weight: 500;
      }
    }
  }
  .field {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 30px;
    cursor: pointer;
    white-space: nowrap;
    padding-left: 9px;

    // gap: em(5);
    &.-header {
      font-weight: 600;
    }
    .entry {
      font-size: em(12);
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      &.-one {
        width: 50%;
      }
      &.-two {
        width: 25%;
        justify-content: flex-end;
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.-three {
        width: 25%;
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
