@use "../utils/" as *;

.navbar {
  background-color: #101010;
  position: sticky;
  z-index: 2000;
  top: 0;
  transition: 0.3s ease all;
  max-width: 100%;
  display: flex;
  height: 10vh;
  align-items: center;
  // background: #1c92d2;
  justify-items: baseline;
  font-weight: normal;
  line-height: 2em;
  font-size: em(20);

  // background: -webkit-linear-gradient(to right, #f2fcfe, #1c92d2);

  // background: linear-gradient(to right, #f2fcfe, #1c92d2);

  // background-position: center;
  background-size: cover;

  &:hover {
    color: var(--text);
  }
  &.-standard {
    // box-shadow: em(0) em(2) em(2) rgba(0, 0, 0, 0.25);
    // background: var(--bg);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  @include respond(tab-port) {
    border-bottom: none;
  }
  &__inner {
    width: 1300px;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
  &__list {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    &.-main {
      letter-spacing: -em(0.2);
      font-weight: 500;
      font-size: rem(18);
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .navbar__list__item {
        &:hover {
          color: var(--main);
          cursor: pointer;
        }
      }
    }

    @include respond(phone) {
      padding: 0;
    }

    &__item {
      display: flex;

      justify-content: center;
      align-items: center;
      &:hover {
        color: var(--main-80);
        cursor: pointer;
      }
      @include respond(tab-port) {
        display: none;
      }
    }
  }

  &__link {
    padding: 0 em(16);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    display: relative;
    &:hover {
      color: var(--main);
    }
  }

  &__logo {
    width: em(100);
    height: em(50);

    display: flex;

    @include respond(phone) {
      width: em(80);
      height: em(40);
    }
    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__button {
    display: none;

    @include respond(tab-port) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 65%;
      width: 4rem;
      cursor: pointer;

      &__component {
        display: none;

        @include respond(tab-port) {
          display: block;
          height: 0.2rem;
          width: 100%;
          background-color: var(--text);
        }
      }
    }
  }
}
