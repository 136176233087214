@use "../utils/" as *;

.stats {
  width: em(350);
  height: min-content;
  // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  display: flex;
  border: em(0.5) solid rgb(227, 225, 225);
  @include respond(phone) {
    width: 100%;
  }
  &.-cap {
    margin: 0;
    width: 100%;
    margin-bottom: em(10);
    @include respond(phone) {
      margin-left: 0;
    }
    // margin-left: em(20);
  }
  border-radius: em(5);
  .data {
    width: 100%;
    display: flex;

    justify-content: space-around;
    @include respond(phone) {
      margin-left: 0;
      // flex-direction: column;
    }
    .price {
      // color: black;
      color: rgba(255, 255, 255, 1);
      font-weight: bold;
    }
    .market {
      color: rgba(255, 255, 255, 1);
      opacity: 0.7;
    }
    padding: em(10) 0;
  }

  .indicator {
    border-radius: em(5) 0 0 em(5);
    width: em(10);
    // height: 100%;
    background-color: var(--success-40);
    margin-right: em(10);
  }
}
