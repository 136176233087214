@use "../utils/" as *;

.footer {
  padding: em(10);
  // background-color: #f7feff;
  background-color: #101010;
  display: flex;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  .inner-wrapper {
    max-width: 1300px;
    // padding: 0 em(20);
    color: rgba(255, 255, 255, 1);
    .row {
      display: flex;
      flex-wrap: wrap;
      & > *:nth-child(1) {
        flex: 1 1 30%;
        min-width: 25ch;
      }
      & > *:nth-child(2) {
        flex: 1 1 70%;
      }
      .logo_div {
        // width: em(500);
        margin-bottom: em(15);
        .logo {
          width: em(100);
          height: em(50);
          margin-bottom: em(15);
          a {
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        p {
          padding-left: em(10);
          // color: rgba(0, 40, 78, 0.8);
          opacity: 0.7;
        }
        ul {
          display: flex;
          @include respond(phone) {
            justify-content: center;
          }

          li {
            margin-left: em(10);
            .social_icons {
              display: flex;
              justify-content: center;
              align-items: center;
              // background-color: #eef4fb;
              padding: em(5);
              border-radius: em(5);
              transition: 300ms all ease-in-out;
              border: em(0.5) solid rgb(227, 225, 225);
              &:hover {
                // background-color: #738bae;
                color: #1a90ff;
                border: em(0.5) solid #1a90ff;
              }
            }
          }
        }
      }
      .links_div {
        display: flex;
        flex-wrap: wrap;
        & > * {
          flex: 1;
          text-align: center;
          min-width: 20ch;
        }
        .links {
          h5 {
            font-size: em(24);
            margin-bottom: em(10);
          }
          ul {
            display: flex;
            flex-direction: column;
            // color: rgba(0, 40, 78, 0.8);
            li {
              transition: 300ms all ease-in-out;
              opacity: 0.7;
              &:hover {
                color: #1e75bb;
              }
            }
          }
        }
        .services {
          h5 {
            font-size: em(24);
            margin-bottom: em(10);
          }
          ul {
            display: flex;
            flex-direction: column;
            // color: rgba(0, 40, 78, 0.8);
            li {
              transition: 300ms all ease-in-out;
              &:hover {
                color: #1e75bb;
              }
            }
          }
        }
        .legal {
          h5 {
            font-size: em(24);
            margin-bottom: em(10);
          }
          ul {
            display: flex;
            flex-direction: column;
            // color: rgba(0, 40, 78, 0.8);
            li {
              transition: 300ms all ease-in-out;
              opacity: 0.7;
              &:hover {
                color: #1e75bb;
              }
            }
          }
        }
      }
    }
    .disclaimer {
      display: flex;
      flex-direction: column;
      // color: rgba(0, 40, 78, 0.8);
      p {
        opacity: 0.7;
        margin-bottom: em(10);
        font-size: em(15);
        line-height: em(20);
      }
    }
    .footer_bottom {
      margin-top: em(30);
      display: flex;
      flex-wrap: wrap-reverse;
      justify-content: center;
      padding: em(10) 0;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      opacity: 0.7;
      font-size: 17px;
      @include respond(phone) {
        justify-content: center;
        align-items: center;
      }
      .footer_bottom_inner {
        display: flex;
        p {
          margin-block-end: unset !important;
          padding: em(15) 0;
          // color: rgba(0, 40, 78, 0.8);
          @include respond(phone) {
            padding: 0;
          }
        }
      }
      // .footer_bottom_right {
      //   ul {
      //     display: flex;
      //     gap: em(10);
      //     // color: rgba(0, 40, 78, 0.8);
      //     @include respond(phone) {
      //       padding: 0;
      //     }
      //     li {
      //       transition: 300ms all ease-in-out;
      //       &:hover {
      //         color: #1e75bb;
      //       }
      //     }
      //   }
      // }
    }
  }
}
