@use "../utils/" as *;
.page_wrapper {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond(phone) {
    padding: em(20) em(15);
  }
  h2 {
    width: 100%;
    font-size: 18px;
  }
  h3 {
    width: 100%;
    font-size: 18px;
  }
  p {
    width: 100%;
    opacity: 0.7;
  }
  ul {
    padding: 0 em(15);
    li {
      width: 100%;
      opacity: 0.7;
    }
  }

  .sections {
    h3 {
      width: 100%;
      font-size: 18px;
    }
    p {
      width: 100%;
      opacity: 0.7;
    }
    ul > li {
      width: 100%;
      opacity: 0.7;
    }

    ul li::before {
      content: "•";
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      counter-increment: my-counter;
      margin-right: 0.5em;
    }
  }
}
