@use "../utils/" as *;

.TraditionalTab_wrapper {
  // background-color: red;
  //   height: 100vh;
  // color: black;
  color: rgba(255, 255, 255, 1);
  .no_data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: em(300);
    p {
      opacity: 0.7;
    }
  }
  .title {
    font-size: rem(30);
    font-weight: 600;
    margin: 0;
    @include respond(phone) {
      font-size: rem(24);
    }
  }
  .basic_details {
    margin-top: rem(15);
    padding-bottom: rem(30);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    h3 {
      font-size: rem(24);
      font-weight: 500;
      @include respond(phone) {
        font-size: rem(18);
      }
    }
    .details_row {
      display: flex;
      flex-wrap: wrap;
      gap: rem(5);
      color: rgba(255, 255, 255, 1);
      .row_item {
        display: flex;
        align-items: center;
        // background-color: #e5e7eb;
        border: em(0.5) solid rgb(227, 225, 225);
        opacity: 0.7;
        border-radius: rem(5);
        padding: 0 rem(10);
        span {
          display: flex;
          align-items: center;
          margin-left: em(5);
          font-size: rem(16);
          font-weight: 400;
        }
      }
    }
  }
  .other_details {
    padding: rem(25) 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .details_column {
      h3 {
        margin: 0;
        font-size: rem(24);
        font-weight: 500;
        @include respond(phone) {
          font-size: rem(18);
          max-width: em(150);
        }
      }
      ul {
        padding-left: rem(25);
        li {
          width: 100%;
          opacity: 0.7;
        }
      }
      ul li::before {
        content: "•";
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        counter-increment: my-counter;
        margin-right: 0.5em;
      } // @include respond(phone) {
      //   height: 200px;
      //   overflow: hidden;
      // }
    }
  }
  .map_div {
    width: 50%;
    height: 500px;
  }
  .map_img_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: rem(10);
    .map_wrapper {
      flex: 1;
      height: rem(300);
      min-width: 200px;
      @include respond(phone) {
        height: rem(200);
      }
    }
    .img_wrapper {
      flex: 1;
      min-width: 200px;
      height: rem(300);
      @include respond(phone) {
        height: rem(200);
      }
    }
  }
  .toggle_btn_div {
    display: none;
    width: 100%;
    padding-top: rem(50);
    opacity: 1;
    @include respond(phone) {
      display: block;
    }
  }
}

.leaflet-container {
  height: 400px;
  width: 100%;
}

.TraditionalTab_loader {
  display: flex;
  justify-content: center;
}
