@use "../utils/" as *;

.analyticsDetails {
  // color: #3a3737;
  max-width: 1300px;
  padding: rem(25) 15px;
  margin: auto;
  &__innerDiv {
    .breadcrumb {
      color: rgba(255, 255, 255, 1);
      opacity: 0.7;
      .link {
        font-size: em(14);
        &:hover {
          text-decoration: underline;
        }
        @include respond(phone) {
          font-size: rem(12);
        }
      }
      @include respond(phone) {
        font-size: rem(12);
      }
    }

    .info__div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: rem(20);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      // color: #3a3737;
      column-gap: rem(100);
      .main__info {
        // width: 55%;
        flex: 1 1 45%;
        // background-color: yellow;
        display: flex;
        flex-direction: column;

        .inner_wrapper {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          column-gap: em(30);
          .inner_wrapper_left {
            flex: 1 1;
            min-width: em(200);
            .title_div {
              display: flex;
              align-items: center;
              margin-bottom: em(20);
              .title_inner {
                display: flex;
                flex-direction: column;
                padding-left: rem(20);
                color: rgba(255, 255, 255, 1);
                h2 {
                  margin: 0;
                  font-size: rem(24);
                  font-weight: 500;
                  @include respond(phone) {
                    font-size: rem(24);
                  }
                }
                p {
                  opacity: 0.7;
                  margin: 0;
                }
              }
            }
            .price_div {
              display: flex;
              flex-direction: column;
              margin-bottom: em(20);
              width: 100%;
              .price {
                display: flex;
                align-items: center;
                color: rgba(255, 255, 255, 1);
                h1 {
                  color: rgba(255, 255, 255, 1);
                  margin: 0;
                  font-size: rem(34);
                  font-weight: 700;
                }
                p {
                  margin: 0;
                }
              }
              .price_bar {
                width: 100%;
                .values {
                  display: flex;
                  justify-content: space-between;
                  color: rgba(255, 255, 255, 1);
                  opacity: 0.7;
                }
                @include respond(phone) {
                  width: 100%;
                }
              }
              @include respond(phone) {
                width: 100%;
              }
            }
          }
          .inner_wrapper_right {
            flex: 1 1;
            min-width: 50%;
            .other__info {
              width: 100%;
              h2 {
                color: rgba(255, 255, 255, 1);
                margin: 0;
                font-size: rem(24);
                font-weight: 500;
                @include respond(phone) {
                  font-size: rem(20);
                }
              }
              .info_inner {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                .value_div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-wrap: wrap;
                  font-weight: 600;
                  font-size: em(14);
                  color: rgba(255, 255, 255, 1);
                  opacity: 0.7;
                  span {
                    padding: rem(2) rem(10);
                    // background-color: #e5e7eb;
                    border: em(0.5) solid rgb(227, 225, 225);
                    border-radius: 5px;
                    margin: rem(2);

                    a {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      column-gap: em(4);
                      &:hover {
                        color: #1a90ff;
                        transition: transform 0.8 ease-out;
                        -webkit-transition: 0.8 ease-out;
                      }
                    }
                    .copyIcon {
                      z-index: 1000;
                      color: rgba(255, 255, 255, 1);
                      &:hover {
                        color: #1a90ff;
                        transition: transform 0.8 ease-out;
                        -webkit-transition: 0.8 ease-out;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .metrics_div {
          //   background-color: red;
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          column-gap: rem(30);
          margin: rem(10) 0;
          .metric_detail {
            color: rgba(255, 255, 255, 1);
            opacity: 0.7;
            min-width: rem(300);
            flex: 1 1;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid lightgray !important;
            font-size: rem(14);
            .key {
              font-weight: 300;
            }
            .value {
              font-weight: 600;
            }
          }
        }
      }
      .twitter-widget {
        flex: 1 1 25%;
        height: 100%;
        @include respond(phone) {
          width: 100%;
        }
      }
    }
    .tabs_div {
      width: 100%;
    }
    .about_div {
      // background-color: red;
      margin: em(12) em(0);
      h3 {
        margin: 0 0 em(10) 0;
        font-size: rem(30);
        font-weight: 500;
        @include respond(phone) {
          font-size: rem(24);
          margin-bottom: em(10);
        }
      }
      .para-sm {
        display: none;
        @include respond(phone) {
          display: block;
          span {
            color: #1a90ff;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .para-lg {
        @include respond(phone) {
          display: none;
        }
      }
      p {
        font-size: 16px;
        line-height: em(25);
      }
    }
  }
}

.css-19kzrtu {
  padding: rem(10) 0 !important;
}

.MuiTouchRipple-root {
  border: none !important;
}
