@use "../utils/" as *;

.reanalyticspage {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  .reAnalytics_inner {
    width: 100%;
    padding: em(25) em(0);
    @include respond(phone) {
      padding: em(15);
    }
  }
}
.ag-root-wrapper {
  border: 0 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding: em(12) em(0) em(12) em(0) !important;
  margin-right: em(12) !important;
}

//border-bottom for tabs

.css-1gsv261 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.generalinfo {
  display: flex;
  flex-direction: column;
  padding: em(10);
  width: 100%;
  align-items: center;
  justify-content: center;
  .market{ 
    gap: em(10);
    // width: 100%;
    // height: 100%;
  }
}
