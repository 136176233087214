html {
    box-sizing: border-box;
    font-size: 100%;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  h1,
  h2,
  h3 {
    line-height: 1.1;
    margin-top: 0;
  }
  p {
    margin-top: 0;
  }
  
  body {
    font-family: var(--font-poppins);
    background: var(--bg);
    color: var(--text);
  }
  
  button {
    border: none;
    background-color: transparent;
    font-family: inherit;
    padding: 0;
    font-size: inherit;
    cursor: pointer;
  }
  