@use "../utils/" as *;

.overview {
  display: flex;
  justify-content: space-between;
  @include respond(tab-land) {
    flex-direction: column;
  }
  .viewoptions {
    flex: 2;
    @include respond(tab-land) {
      margin-bottom: em(40);
    }
    .title {
      font-size: rem(30);
      font-weight: 600;
      margin: 0;
      @include respond(phone) {
        font-size: rem(24);
      }
    }
    .togglers {
      display: flex;
      justify-content: space-between;
      row-gap: em(10);
      @include respond(tab-land) {
        flex-direction: column;
      }
      .timeline {
        @include respond(phone) {
          width: 100%;
        }
      }
    }
  }
  .pricechart {
    position: relative;
  }
  .stats {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 em(20);
    @include respond(tab-port) {
      margin: 0;
    }
    .tokenstats {
      margin-bottom: em(20);
    }
  }
}
