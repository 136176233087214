@use "../utils/" as *;

.regrid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: em(40);
  .regrid_options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: em(10);
  }
  .iconButton {
    color: rgba(255, 255, 255, 1);
  }
}

.regrid_column_toggle {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: em(10);

  .toggle {
  }
}
.common_class {
  padding: em(15);
  font-size: em(16);
  @include respond(phone) {
    padding: em(10);
    height: em(50) !important;
  }
}

.main_title {
  font-size: em(16);
  padding: em(15);
  &:hover {
    text-decoration: underline;
    text-decoration-color: #1a90ff;
  }
}

.ag-cell-focus,
.ag-cell {
  border: none !important;
}

.companycell {
  text-transform: capitalize;
  &:hover {
    color: #1a90ff;
  }
}

.ag-icon {
  color: rgba(255, 255, 255, 1);
}

.ag-picker-field-wrapper {
  color: rgba(255, 255, 255, 1) !important;
}

.ag-popup {
  color: rgba(255, 255, 255, 1) !important;
}
.ag-react-container {
  background-color: #101010 !important;
}

.MuiSvgIcon-root {
  color: rgba(255, 255, 255, 1) !important;
}
.MuiFormLabel-root {
  color: rgba(255, 255, 255, 1) !important;
}

// .ag-overlay-loading-wrapper {
//   background-color: transparent !important;
// }
